import React, { useState, useEffect } from 'react';
import { Box, Typography, Divider, Button, Modal, IconButton, SelectChangeEvent, Grid, TextField, Autocomplete, Checkbox, FormControlLabel, FormControl, InputLabel, Select, MenuItem, Alert, AlertTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { API_PATH } from '../../../App';
import { getAxiosConfig } from '../../../helpers/AuthHelper';
import MachineDetailsTable from './MachineDetailsTable';
import { InvoiceModalProps, Customer, MachineDetail, Machine, ConfirmationDates } from './types';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PhoneIcon from '@mui/icons-material/Phone';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';


const InvoiceModal: React.FC<InvoiceModalProps> = ({
    open,
    isAdding,
    onClose,
    selectedInvoice,
    setTriggerList,
    trigerList
}) => {
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [machineDetails, setMachineDetails] = useState<MachineDetail[]>([]);
    const [newMachine, setNewMachine] = useState<MachineDetail>({
        id: null,
        model: '',
        quantity: 0,
        selectedModel: null,
        price: 0,
        hostingCharge: '',
        pluginDays: '',
        warranty: '',
        insurance: '',
        budget: '',
        endDate: '',
    });
    const [selectedCustomerId, setSelectedCustomerId] = useState<number | ''>('');
    const [selectedCustomerNumber, setSelectedCustomerNumber] = useState<string | ''>('');
    const [machineList, setMachineList] = useState<Machine[]>([]);
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [note, setNote] = useState<string>('');
    const [referenceNumber, setReferenceNumber] = useState<string>('');
    const [vatChecked, setVatChecked] = useState<boolean>(false);
    const [selectedInvoiceId, setSelectedInvoiceId] = useState<number | null>(null)
    const [selectedCustomerGroupName, setSelectedCustomerGroupName] = useState<string | ''>('');
    const [confirmationDate, setConfirmationDate] = useState<Date | null>(null);
    const [invoiceStatus, setInvoiceStatus] = useState<string>("PENDING");
    const [saveButtonLoader, setSaveButtonLoader] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState<boolean>(false)
    const [confirmationDates, setConfirmationDates] = useState<ConfirmationDates>({
        pluginDate: '',
        warrantyDate: '',
        insuranceDate: '',
        endDate: '',
    })
    const [type, setType] = useState<string>('');
    const [billTo, setBillTo] = useState<string>('');

    const [alertControl, setAlertControl] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState<'error' | 'success' | 'info' | 'warning'>('success');



    var isDateEditable: boolean = (selectedInvoice === null) || (selectedInvoice.status === "PENDING");

    const resetStates = () => {
        setCustomers([]);
        setMachineDetails([{
            id: null,
            model: '',
            selectedModel: null,
            quantity: 0,
            price: 0,
            hostingCharge: '',
            pluginDays: '',
            warranty: '',
            insurance: '',
            budget: '',
            endDate: '',

        }]);
        setNewMachine({
            id: null,
            model: '',
            selectedModel: null,
            quantity: 0,
            price: 0,
            hostingCharge: '',
            pluginDays: '',
            warranty: '',
            insurance: '',
            budget: '',
            endDate: '',

        });
        setSelectedCustomerId('');
        setSelectedCustomerNumber('');
        setSelectedDate(new Date());
        setNote('');
        setReferenceNumber('');
        setVatChecked(false);
        setSelectedInvoiceId(null);
        setSelectedCustomerGroupName('');
        setConfirmationDate(null);
        setInvoiceStatus("PENDING");
        setSaveButtonLoader(false);
        setAlertControl(false);
        setReadOnly(false);
        setType('');
        setBillTo('')
    };


    useEffect(() => {
        if (open) {
            if (selectedInvoice && !isAdding) {
                setReadOnly(selectedInvoice.status !== "PENDING" ? true : false)
                setSelectedInvoiceId(selectedInvoice.id);
                setSelectedCustomerId(selectedInvoice.customer.id);
                setSelectedCustomerNumber(selectedInvoice.customer.number || '');
                setSelectedCustomerGroupName(selectedInvoice.customer.customerGroupName || '');
                setReferenceNumber(selectedInvoice.reference);
                setVatChecked(selectedInvoice.addVat);
                setSelectedDate(new Date(selectedInvoice.invoiceDate));
                setNote(selectedInvoice.note);
                setInvoiceStatus(selectedInvoice.status);
                setConfirmationDate(new Date());
                setType(selectedInvoice.invoiceType || '');
                setBillTo(selectedInvoice.billTo || '')

                const prefilledMachineDetails = selectedInvoice.invoiceItems.map((item: any) => ({
                    id: item.id,
                    model: item.model.id,
                    selectedModel: item.model,
                    quantity: item.qty,
                    price: item.price,
                    hostingCharge: item.hostingCharge.toString(),
                    pluginDays: item.pluggedInDays.toString(),
                    warranty: item.warrantyExpiryDays?.toString() || '',
                    insurance: item.insuranceExpiryDays?.toString() || '',
                    budget: item?.budget,
                    endDate: item?.endDate,
                    type: item?.invoiceType,
                    billTo: item?.billTo,
                }));

                setMachineDetails(prefilledMachineDetails);

            } else if (isAdding) {
                resetStates()
            }
        }
    }, [open, selectedInvoice, isAdding]);

    useEffect(() => {
        fetchMachineList();
    }, []);

    const handleReferenceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReferenceNumber(event.target.value);
    };

    const handleVatChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVatChecked(event.target.checked);
    };

    const handleCustomerSearch = async (event: React.SyntheticEvent<Element, Event>, value: string) => {
        setAlertControl(false)
        if (value.length >= 2) {
            axios.get(`${API_PATH}/customer/search?key=${value}`, getAxiosConfig())
                .then(response => {
                    setCustomers(response.data);
                });
        }
    };

    const fetchMachineList = () => {
        axios.get(API_PATH + '/machine_model/list', getAxiosConfig())
            .then(response => {
                setMachineList(response.data);
            })
            .catch(error => {
                console.error("Error fetching machine list:", error);
            });
    };

    const handleCustomerChange = (event: React.SyntheticEvent, value: Customer | null) => {
        if (value) {
            setSelectedCustomerId(value.id);
            setSelectedCustomerNumber(value.number || '');
            setSelectedCustomerGroupName(value.customerGroupName || '');
        } else {
            setSelectedCustomerId('');
            setSelectedCustomerNumber('');
            setSelectedCustomerGroupName('');
        }
    };

    const handleTypeChange = (event: SelectChangeEvent) => {
        setType(event.target.value as string);
        setAlertControl(false)
    };

    const handleBillToChange = (event: SelectChangeEvent) => {
        setBillTo(event.target.value as string);
        setAlertControl(false)
    };

    const handleAddMachine = () => {
        setAlertControl(false)
        setMachineDetails((prev) => [...prev, newMachine]);
        setNewMachine({
            id: null,
            model: '',
            selectedModel: null,
            quantity: 0,
            price: 0,
            hostingCharge: '',
            pluginDays: '',
            warranty: '',
            insurance: '',
            budget: '',
            endDate: '',
        });
    };

    const handleMachineChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setAlertControl(false)
        const { name, value } = event.target;
        setMachineDetails((prev) => {
            const updatedDetails = [...prev];
            updatedDetails[index] = {
                ...updatedDetails[index],
                [name]: value
            };
            return updatedDetails;
        });
    };

    const handleModelSelectChange = (index: number) => (event: SelectChangeEvent<string>) => {
        const selectedModel: Machine = JSON.parse(event.target.value);
        setAlertControl(false)
        setMachineDetails((prev) => {
            const updatedDetails = [...prev];
            updatedDetails[index] = {
                ...updatedDetails[index],
                model: String(selectedModel.id),
                selectedModel: selectedModel
            };
            return updatedDetails;
        });
    };

    const handleDeleteMachine = (index: number) => () => {
        setMachineDetails((prev) => prev.filter((_, i) => i !== index));
    };

    const calculateTotalPrice = () => {
        return machineDetails.reduce((total, detail) => {
            const price = detail.price;
            return total + price * detail.quantity;
        }, 0);
    };

    const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNote(event.target.value)
        calculateTotalPrice()
    };

    function addDays(date: Date, days: string): Date {
        const result = new Date(date);
        result.setDate(result.getDate() + parseInt(days, 10));
        return result;
    }


    const handleSubmit = async () => {
        setSaveButtonLoader(true);
        let alertMessage = '';
        let alertSeverity: 'error' | 'warning' | 'info' | 'success' = 'success';

        const totalPrice = calculateTotalPrice();
        const invoiceData = {
            id: selectedInvoiceId || null,
            customer: selectedCustomerId,
            reference: referenceNumber,
            invoiceDate: formatDateToYYYYMMDD(selectedDate),
            addVat: vatChecked,
            totalPrice,
            vatAmount: vatChecked ? (totalPrice * 0.05) : 0,
            grandTotal: totalPrice * 1.05,
            status: invoiceStatus || "PENDING",
            confirmationDate: confirmationDate ? formatDateToYYYYMMDD(confirmationDate) : null,
            note,
            type,
            billTo,
            invoiceItems: machineDetails.map(detail => ({
                id: detail.id,
                model: detail.selectedModel?.id,
                qty: detail.quantity,
                price: detail.price,
                hostingCharge: Number(detail.hostingCharge),
                pluggedInDays: Number(detail.pluginDays),
                pluggedInDate: (confirmationDate && detail.pluginDays) ?
                    (confirmationDates.pluginDate !== '' ?
                        formatDateToYYYYMMDD(confirmationDates.pluginDate) :
                        formatDateToYYYYMMDD(addDays(confirmationDate, detail.pluginDays))) : null,
                hasWarranty: !!detail.warranty,
                warrantyExpiry: (confirmationDate && confirmationDates.warrantyDate) ?
                    (confirmationDates.warrantyDate !== '' ?
                        formatDateToYYYYMMDD(confirmationDates.warrantyDate) :
                        formatDateToYYYYMMDD(addDays(confirmationDate, detail.warranty))) : null,
                warrantyExpiryDays: Number(detail.warranty),
                hasInsurance: true,
                insuranceExpiry: (confirmationDate && confirmationDates.warrantyDate) ?
                    (confirmationDates.warrantyDate !== '' ?
                        formatDateToYYYYMMDD(confirmationDates.insuranceDate) :
                        formatDateToYYYYMMDD(addDays(confirmationDate, detail.insurance))) : null,
                insuranceExpiryDays: Number(detail.insurance),
                isActive: true,
                budget: detail.budget,
                endDate: detail.endDate,
            }))
        };

        const showAlert = (message: string, severity: 'error' | 'warning' | 'info' | 'success') => {
            setSaveButtonLoader(false);
            setAlertControl(true);
            setAlertMessage(message);
            setAlertSeverity(severity);
        };

        const validateInvoiceData = () => {
            if (!invoiceData.customer) return "Customer is missing.";
            if (!invoiceData.type) return "Type is missing.";
            if (!invoiceData.billTo) return "Bill To is missing.";
            if (invoiceData.invoiceItems.some(item => item.model === undefined)) return "Select Machine model";
            if (invoiceData.invoiceItems.some(item => item.qty === 0)) return "Select Machine Quantity.";

            if (invoiceData.type === 'CLOUD_SALE' && invoiceData.invoiceItems.some(item => !item.endDate)) {
                return "End Date is required.";
            } else if (['PROMOTION', 'REPAIR'].includes(invoiceData.type) &&
                invoiceData.invoiceItems.some(item => !item.budget)) {
                return "Budget is required.";
            }
            return null;
        };

        const validationError = validateInvoiceData();
        if (validationError) {
            showAlert(validationError, 'error');
            return;
        }

        axios.post(API_PATH + '/invoice/save', invoiceData, getAxiosConfig())
            .then(response => {
                setSaveButtonLoader(false);

                alertMessage = invoiceStatus === "CONFIRMED" ?
                    "Invoice Confirmed and Machines are listed." :
                    "Invoice is Moved to Pending.";
                alertSeverity = 'success';
                showAlert(alertMessage, alertSeverity);

                setReadOnly(true);
                setTimeout(onModalClose, 5000);
                setTriggerList(!trigerList);
            })
            .catch(e => {
                showAlert(`${e}`, 'error');
                setSaveButtonLoader(false);
            });
    };


    const handleInvoiceOptionChange = (event: SelectChangeEvent<string>) => {
        setInvoiceStatus(event.target.value);
    };

    const handleEndDateChange = (index: number) => (date: Date | null): void => {
        setAlertControl(false)
        setMachineDetails((prev) => {
            const updatedDetails = [...prev];
            updatedDetails[index] = {
                ...updatedDetails[index],
                endDate: date ? formatDateToYYYYMMDD(date) : '',
            };
            return updatedDetails;
        });
    };

    const handleBudgetChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setAlertControl(false)
        setMachineDetails((prev) => {
            const updatedDetails = [...prev];
            updatedDetails[index] = {
                ...updatedDetails[index],
                budget: event.target.value,
            };
            return updatedDetails;
        });
    };

    const onModalClose = () => {
        onClose()
        resetStates()
    }

    function formatDateToYYYYMMDD(date: string | Date): string {
        const dateObj = typeof date === 'string' ? new Date(date) : date;

        const day = String(dateObj.getDate()).padStart(2, '0');
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const year = dateObj.getFullYear();

        return `${year}-${month}-${day}`;
    }

    return (
        <Modal
            open={open}
            onClose={onModalClose}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}

        >
            <Box sx={{ width: '90%', minHeight: '90vh', maxHeight: '90vh', bgcolor: 'background.paper', p: 4, position: 'relative', overflowY: 'auto' }}>
                <IconButton
                    onClick={onModalClose}
                    sx={{ position: 'absolute', top: 16, right: 16 }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" gutterBottom sx={{ textAlign: 'left' }}>
                    {(selectedInvoice && !isAdding) ? `Invoice #${selectedInvoiceId}` : "Add New Invoice"}
                </Typography>
                <Divider sx={{ my: 2 }} />

                <Box sx={{ width: '100%' }}>
                    <Grid container spacing={2}>

                        <Grid item md={3}>
                            {selectedCustomerId !== '' ? (
                                <TextField
                                    value={selectedCustomerGroupName}
                                    InputProps={{

                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    size="small"
                                    label="Customer"
                                    fullWidth
                                    disabled={invoiceStatus !== "PENDING"}
                                />
                            )
                                :
                                <Autocomplete
                                    options={customers}
                                    getOptionLabel={(option) => `${option.customerGroupName}`}
                                    onInputChange={handleCustomerSearch}
                                    onChange={handleCustomerChange}
                                    fullWidth
                                    disabled={invoiceStatus !== "PENDING"}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Search Customer"
                                            variant="outlined"
                                            size="small"
                                        />
                                    )}
                                />

                            }
                        </Grid>

                        <Grid item md={3}>
                            {selectedCustomerId !== '' && (
                                <TextField
                                    value={selectedCustomerNumber}
                                    InputProps={{
                                        startAdornment: (
                                            <PhoneIcon sx={{ mr: 1 }} />
                                        ),
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    disabled={invoiceStatus !== "PENDING"}
                                />
                            )}
                        </Grid>
                        <Grid item md={3}></Grid>
                        <Grid item md={3}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Select Invoice Date"
                                    value={selectedDate}
                                    onChange={(date: any) => setSelectedDate(date)}
                                    disabled={invoiceStatus !== "PENDING"}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                    <TextField
                        label="Reference Number"
                        variant="outlined"
                        value={referenceNumber}
                        onChange={handleReferenceChange}
                        sx={{ mr: 2, width: '20%' }}
                        size="small"
                        disabled={invoiceStatus !== "PENDING"}
                    />
                    <FormControl sx={{ mr: 2, width: '20%' }} size="small" required disabled={invoiceStatus !== "PENDING"}>
                        <InputLabel>Type</InputLabel>
                        <Select
                            value={type}
                            onChange={handleTypeChange}
                            label="Type"
                        >
                            <MenuItem value="">Select Type</MenuItem>
                            <MenuItem value="MACHINE_SALE">Machine Sales</MenuItem>
                            <MenuItem value="CLOUD_SALE">Cloud Sales</MenuItem>
                            <MenuItem value="PROMOTION">Promotion</MenuItem>
                            <MenuItem value="REPAIR">Repair</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl sx={{ mr: 2, width: '20%' }} size="small" required disabled={invoiceStatus !== "PENDING"}>
                        <InputLabel>Bill To</InputLabel>
                        <Select
                            value={billTo}
                            onChange={handleBillToChange}
                            label="Bill To"
                        >
                            <MenuItem value="">Select Bill To</MenuItem>
                            <MenuItem value="CLOUD">Cloud</MenuItem>
                            <MenuItem value="HOSTING">Hosting</MenuItem>
                            <MenuItem value="SALES">Sales</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={vatChecked}
                                onChange={handleVatChange}
                                color="primary"
                                disabled={invoiceStatus !== "PENDING"}
                            />
                        }
                        label="ADD VAT"
                    />
                </Box>
                <MachineDetailsTable
                    machineDetails={machineDetails}
                    machineList={machineList}
                    note={note}
                    vatChecked={vatChecked}
                    handleModelSelectChange={handleModelSelectChange}
                    handleMachineChange={handleMachineChange}
                    handleDeleteMachine={handleDeleteMachine}
                    handleAddMachine={handleAddMachine}
                    handleNoteChange={handleNoteChange}
                    calculateTotalPrice={calculateTotalPrice}
                    disabled={invoiceStatus !== "PENDING"}
                    confirmationDate={confirmationDate!}
                    overideExpiryDateEdit={isDateEditable}
                    setConfirmationDates={setConfirmationDates}
                    confirmationDates={confirmationDates}
                    type={type}
                    billTo={billTo}
                    handleEndDateChange={handleEndDateChange}
                    handleBudgetChange={handleBudgetChange}
                />
                <Box>
                    <Grid container spacing={2}>
                        {selectedInvoice !== null &&
                            <Grid item xs={4}>
                                <FormControl fullWidth >
                                    <InputLabel id="selection-label">Select Option</InputLabel>
                                    <Select
                                        labelId="selection-label"
                                        value={invoiceStatus}
                                        onChange={handleInvoiceOptionChange}
                                        label="Select Option"
                                        disabled={readOnly}
                                    >
                                        <MenuItem value={"PENDING"}>INVOICE PENDING</MenuItem>
                                        <MenuItem value={"CONFIRMED"}>INVOICE CONFIRMED</MenuItem>
                                        <MenuItem value={"REJECTED"}>INVOICE REJECTED</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                        {invoiceStatus === "CONFIRMED" &&
                            <Grid item xs={4}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Confirmation Date"
                                        value={confirmationDate}
                                        onChange={(date: Date | null) => setConfirmationDate(date)}
                                        disabled={readOnly}
                                    />
                                </LocalizationProvider>
                            </Grid>

                        }
                    </Grid>
                </Box>
                {alertControl && (
                    <Alert sx={{ my: 2 }} severity={alertSeverity}>
                        <AlertTitle>{alertSeverity === 'error' ? 'Error' : 'Success'}</AlertTitle>
                        {alertMessage}
                    </Alert>
                )}


                <Box sx={{ mt: 2, textAlign: 'left' }}>
                    {saveButtonLoader ?
                        <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined" sx={{ mr: 2 }}>
                            Save
                        </LoadingButton>
                        :
                        <Button variant="contained" sx={{ mr: 2 }} onClick={handleSubmit} disabled={readOnly}>
                            Save
                        </Button>
                    }
                </Box>
            </Box>
        </Modal>
    );
};

export default InvoiceModal;
