// csvUtils.ts

export interface WorkerData {
    user: string;
    totalSpend?: number;
    expenseInDollar?: number;
    hostingFee?: string;
    Power?: string;
}

export interface Column {
    header: string;
    accessorKey: keyof WorkerData | string;
}


export const downloadGroupedCSV = (
    rows: WorkerData[],
    selectedHours: number,
    formatDate: (date: Date) => string
): void => {
    const groupedRows = rows.reduce<Record<string, WorkerData[]>>((acc, row) => {
        const user = row.user;
        if (!acc[user]) {
            acc[user] = [];
        }
        acc[user].push(row);
        return acc;
    }, {});

    // Initialize CSV rows with headers
    const csvRows: string[] = [
        ['User', 'Total Expense BTC', 'Total Expense Dollar', 'Total Income', 'Average Hosting Fee', 'Average Power', 'Overall Total'].join(',')
    ];

    // Initialize overall totals
    let overallExpenseBTC = 0;
    let overallExpenseDollar = 0;
    let overallIncome = 0;
    let totalHostingFeeSum = 0;
    let totalPowerSum = 0;
    let totalRowCount = 0;
    let overallTotal = 0;

    // Iterate over each user group to calculate totals and averages
    Object.entries(groupedRows).forEach(([user, userRows]) => {
        let totalExpenseBTC = 0;
        let totalExpenseDollar = 0;
        let totalIncome = 0;
        let totalHostingFee = 0;
        let totalPower = 0;

        userRows.forEach(row => {
            const expenseBTC = parseFloat(row.totalSpend?.toString() || '0');
            const expenseDollar = parseFloat(row.expenseInDollar?.toString() || '0');
            const hostingFee = parseFloat(row.hostingFee || '0.06');
            const power = parseFloat(row.Power || '3.2');
            const income = hostingFee * power * selectedHours * 24;
            const total = income - expenseDollar;

            totalExpenseBTC += expenseBTC;
            totalExpenseDollar += expenseDollar;
            totalIncome += income;
            totalHostingFee += hostingFee;
            totalPower += power;
            overallTotal += total;

            // Accumulate overall totals
            overallExpenseBTC += expenseBTC;
            overallExpenseDollar += expenseDollar;
        });

        // Update sums for overall averages
        totalHostingFeeSum += totalHostingFee;
        totalPowerSum += totalPower;
        totalRowCount += userRows.length;
        overallIncome += totalIncome;

        // Calculate averages for this user
        const averageHostingFee = totalHostingFee / userRows.length;
        const averagePower = totalPower / userRows.length;

        // Add row for this user to the CSV
        csvRows.push([
            user,
            totalExpenseBTC.toFixed(8),
            totalExpenseDollar.toFixed(8),
            totalIncome.toFixed(2),
            averageHostingFee.toFixed(4),
            averagePower.toFixed(4),
            overallTotal.toFixed(2)
        ].join(','));
    });

    // Calculate overall averages
    const overallAverageHostingFee = totalHostingFeeSum / totalRowCount;
    const overallAveragePower = totalPowerSum / totalRowCount;

    // Add overall summary row at the end
    csvRows.push([
        "Overall Total",
        overallExpenseBTC.toFixed(8),
        overallExpenseDollar.toFixed(8),
        overallIncome.toFixed(2),
        overallAverageHostingFee.toFixed(4),
        overallAveragePower.toFixed(4),
        overallTotal.toFixed(2)
    ].join(','));

    // Convert to CSV and trigger download
    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `worker_data_grouped_${formatDate(new Date())}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    window.URL.revokeObjectURL(url);
};
