import * as React from 'react';
import TableSortLabel from '@mui/material/TableSortLabel';
import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Box, Typography, Radio, RadioGroup, FormControlLabel, Select, MenuItem, SelectChangeEvent, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress, Snackbar, Alert, SnackbarCloseReason, Button, Dialog } from '@mui/material';
import axios from 'axios';
import { getAxiosConfig } from '../../../helpers/AuthHelper';
import { API_PATH } from '../../../App';
import TableLoader from '../GlobalComponents/TableLoader';
import { convertHashRate } from '../../utils/HashrateUtils';
import { convertToCustomFormat } from '../../utils/dateUtil';
import { EmitterHash, EmitterHashKey, YourMainDto } from './types';
import { Power,Replay } from '@mui/icons-material';



export default function EmitterHashList() {
    const [rows, setRows] = useState<EmitterHash[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = useState<EmitterHashKey>('worker');
    const [hashType, setHashType] = useState<'hash15Min' | 'hash24Hr'>('hash15Min');
    const [coinFilter, setCoinFilter] = useState<string>('');
    const [uniqueCoins, setUniqueCoins] = useState<string[]>([]);

    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedEmitterId, setSelectedEmitterId] = useState('');

    const [confirmLoader, setConfirmLoader] = useState(false)
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const handleRequestSort = (property: EmitterHashKey) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const stableSort = (array: EmitterHash[], comparator: (a: EmitterHash, b: EmitterHash) => number) => {
        const stabilizedThis = array.map((el, index) => [el, index] as [EmitterHash, number]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };

    const getComparator = (order: 'asc' | 'desc', orderBy: EmitterHashKey) => {
        return (a: EmitterHash, b: EmitterHash) => {

            // @ts-ignore
            if (b[orderBy] < a[orderBy]) return order === 'desc' ? -1 : 1;
            // @ts-ignore
            if (b[orderBy] > a[orderBy]) return order === 'desc' ? 1 : -1;
            return 0;
        };
    };

    useEffect(() => {
        fetchEmitterHashList();
    }, []);

    const fetchEmitterHashList = () => {
        axios.get(API_PATH + '/monitor/emitterhash', getAxiosConfig())
            .then(response => {
                const emitterHashData: EmitterHash[] = response.data;
                const transformedEmitterHashData = emitterHashData.map(item => ({
                    ...item,
                    coin: item.coin ?? 'unknown',
                    workerLength: item.workerList?.length,

                }));
                axios.get(API_PATH + '/nicehash/emitters', getAxiosConfig())
                    .then(response => {
                        const emitterData = response.data;
                        const mergedData = transformedEmitterHashData.map(emitterHash => {
                            const matchingEmitter = emitterData.find((emitter: YourMainDto) => emitter.pool.username === emitterHash.worker);
                            if (matchingEmitter) {
                                return {
                                    ...emitterHash,
                                    endTs: matchingEmitter.endTs,
                                    limit: matchingEmitter.limit,
                                    type: matchingEmitter.type,
                                    algorithm: matchingEmitter.pool.algorithm,
                                    id: matchingEmitter.id,
                                };
                            }
                            return emitterHash;
                        });

                        setRows(mergedData);
                        const coins = Array.from(new Set(mergedData.map(item => item.coin ?? 'unknown').filter(coin => coin !== '')));
                        setUniqueCoins(coins);

                        setLoading(false);
                    })
                    .catch(error => {
                        console.error('Error fetching emitter data:', error);
                        setError('Failed to fetch data.');
                        setLoading(false);
                    });
            })
            .catch(error => {
                console.error('Error fetching emitter hash list:', error);
                setError('Failed to fetch data.');
                setLoading(false);
            });
    };

    const handleCoinFilterChange = (event: SelectChangeEvent<string>) => {
        setCoinFilter(event.target.value);
    };

    const filteredRows = rows.filter(row =>
        coinFilter === '' || row.coin === coinFilter
    );

    const totalHashRate = filteredRows.reduce((total, row) => {
        return total + (hashType === 'hash15Min' ? row.hash15Min : row.hash24Hr);
    }, 0);

    const handleRecreate = () => {
        console.log("id", selectedEmitterId);
        setConfirmLoader(true)
        setLoading(true);

        axios.post(API_PATH + `/nicehash/emitters/${selectedEmitterId}`, null, getAxiosConfig())
            .then(response => {
                console.log(response);
                setConfirmLoader(false)
                setSnackbarMessage('Emitter recreated successfully!');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                handleCloseModal();
                fetchEmitterHashList()
                setLoading(false);
            })
            .catch(error => {
                setConfirmLoader(false);
                let errorMessage = "An error occurred. Please try again.";
                console.error('Error:', error);
                setSnackbarMessage(errorMessage);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                handleCloseModal();
                setLoading(false);
            });
    }


    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason: SnackbarCloseReason) => {
        if (reason === 'clickaway') return;
        setSnackbarOpen(false);
    };

    const handleOpenModal = (id: string) => {
        setSelectedEmitterId(id);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedEmitterId('');
    };

    const DateLocal = (endTs: any) => {
        const date = new Date(endTs)
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}`;

    }


    return (
        <Box sx={{ flexGrow: 1, p: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5" sx={{ color: '#888888' }} gutterBottom>
                        EMITTER HASH LIST
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <RadioGroup
                        row
                        value={hashType}
                        onChange={(e) => setHashType(e.target.value as 'hash15Min' | 'hash24Hr')}
                    >
                        <FormControlLabel value="hash15Min" control={<Radio />} label="15 Min Hash" />
                        <FormControlLabel value="hash24Hr" control={<Radio />} label="24 Hr Hash" />
                    </RadioGroup>

                    <Select
                        value={coinFilter}
                        onChange={handleCoinFilterChange}
                        displayEmpty
                        size='small'
                        sx={{ mr: '16px' }}
                    >
                        <MenuItem value="">
                            <em>All Coins</em>
                        </MenuItem>
                        {uniqueCoins.map((coin, index) => (
                            <MenuItem key={index} value={coin}>
                                {coin}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={12}>
                    {loading ? (
                        <TableLoader />
                    ) : error ? (
                        <Typography color="error">{error}</Typography>
                    ) : (
                        <>
                            <Typography variant="h6" gutterBottom>
                                Total Hash Rate: {totalHashRate.toFixed(2)} TH/s
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="emitter hash table">
                                    <TableHead>
                                        <TableRow sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#333' : '#f5f5f5', color: (theme) => theme.palette.mode === 'dark' ? '#fff' : '#000', padding: '6px' }}>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={orderBy === 'worker'}
                                                    direction={orderBy === 'worker' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('worker')}
                                                >
                                                    Emitter Name
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={orderBy === hashType}
                                                    direction={orderBy === hashType ? order : 'asc'}
                                                    onClick={() => handleRequestSort(hashType)}
                                                >
                                                    Hash Rate
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={orderBy === 'coin'}
                                                    direction={orderBy === 'coin' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('coin')}
                                                >
                                                    Coin
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={orderBy === 'status'}
                                                    direction={orderBy === 'status' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('status')}
                                                >
                                                    Status
                                                </TableSortLabel>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {stableSort(filteredRows, getComparator(order, orderBy)).map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">
                                                    {row.worker}
                                                    {row?.type?.code !== "STANDARD" &&
                                                        <span style={{
                                                            display: 'inline-block',
                                                            borderRadius: '50%',
                                                            backgroundColor: 'rgb(205 204 204)',
                                                            color: '#fff',
                                                            padding: '2px 7px',
                                                            fontWeight: 'bold',
                                                            marginLeft: '5px'
                                                        }}>B</span>
                                                    }
                                                    <br />
                                                    <small
                                                        style={{
                                                            color:
                                                                row.endTs && !isNaN(new Date(row.endTs).getTime()) &&
                                                                    new Date(row.endTs).getTime() - new Date().getTime() <= 24 * 60 * 60 * 1000
                                                                    ? 'red'
                                                                    : 'gray',
                                                        }}
                                                    >
                                                        {row.endTs && DateLocal(row.endTs)}
                                                    </small>
                                                </TableCell>
                                                <TableCell>
                                                    {hashType === 'hash15Min'
                                                        ? row.hash15Min.toFixed(2) + ' TH/s'
                                                        : row.hash24Hr.toFixed(2) + ' TH/s'}
                                                    <br />
                                                    <small style={{ color: 'gray' }}>{row.limit && `Limit: ${convertHashRate(row.limit,row.algorithm)} TH`}</small>
                                                    <br />
                                                    <small style={{ color: 'gray' }}>{row.limit && `Hash Per Worker: ${convertHashRate(row.limit,row.algorithm) / row.workerLength}`}</small>
                                                </TableCell>
                                                <TableCell>
                                                    {row.coin ?? 'unknown'}
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        sx={{
                                                            color: row.status === 'ONLINE' ? 'green' : 'red',
                                                            fontWeight: 'bold',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'start',
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{ mb: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                            fontSize="small"
                                                        >
                                                            <Power /> {row.status}
                                                        </Box>
                                                        <Button
                                                            variant="contained"
                                                            startIcon={<Replay />}
                                                            size="small"
                                                            color="info"
                                                            onClick={() => handleOpenModal(row.id)}
                                                        >
                                                            Recreate
                                                        </Button>
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <Dialog open={isModalOpen} onClose={handleCloseModal}>
                                    <DialogTitle>Confirm Action</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            Are you sure you want to recreate this emitter?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions >
                                        <Button onClick={handleCloseModal} color="primary" sx={{ mr: 1 }}>
                                            Cancel
                                        </Button>
                                        {confirmLoader ? (
                                            <Button color="error" disabled variant="contained">
                                                Confirm <CircularProgress size={18} color="inherit" sx={{ ml: 1 }} />
                                            </Button>
                                        ) : (
                                            <Button onClick={handleRecreate} variant="contained">
                                                Confirm
                                            </Button>
                                        )}
                                    </DialogActions>
                                </Dialog>
                                <Snackbar
                                    open={snackbarOpen}
                                    autoHideDuration={6000}
                                    onClose={handleSnackbarClose}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                >
                                    <Alert severity={snackbarSeverity as 'error' | 'success' | 'info' | 'warning'} sx={{ width: '100%' }}>
                                        {snackbarMessage}
                                    </Alert>
                                </Snackbar>
                            </TableContainer>
                        </>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}
