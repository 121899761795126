import React from 'react';
import { Grid, Button, TextField, Autocomplete, Select, MenuItem, FormControl, SelectChangeEvent } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import Download from '@mui/icons-material/Download';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Search } from '@mui/icons-material';

interface SearchAndFiltersProps {
    searchOptions: string[];
    searchTerm: string[];
    setSearchTerm: React.Dispatch<React.SetStateAction<string[]>>;
    fromDate: Date | null;
    setFromDate: (date: Date | null) => void;
    toDate: Date | null;
    setToDate: (date: Date | null) => void;
    handleSearch: () => void;
    downloadOption: string;
    handleDownloadChange: (event: SelectChangeEvent<string>) => void;
    globalHostingFee: string;
    setGlobalHostingFee: React.Dispatch<React.SetStateAction<string>>;
    globalPower: string;
    setGlobalPower: React.Dispatch<React.SetStateAction<string>>;
    applyGlobalChanges: () => void;
}

const SearchAndFilters: React.FC<SearchAndFiltersProps> = ({
    searchOptions,
    searchTerm,
    setSearchTerm,
    handleSearch,
    downloadOption,
    handleDownloadChange,
    globalHostingFee,
    setGlobalHostingFee,
    globalPower,
    setGlobalPower,
    applyGlobalChanges,
}) => {
    return (
        <Grid
            container
            spacing={1}
            sx={{
                marginBottom: "10px",
                alignItems: 'center',
            }}
        >

            <Grid item xs={12} sm={3} sx={{ minWidth: '200px' }}>
                <Autocomplete
                    multiple
                    options={searchOptions}
                    value={searchTerm}
                    onChange={(event, newValue) => setSearchTerm(newValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Search"
                            size="small"
                            fullWidth
                        />
                    )}
                />
            </Grid>

            <Grid item sm="auto">
                <Button variant="contained" size="small" sx={{ paddingY: 1 }} onClick={handleSearch}>
                    <Search />
                </Button>
            </Grid>

            <Grid item xs={12} sm={3}>
                <TextField
                    label="Global Hosting Fee"
                    value={globalHostingFee}
                    onChange={(e) => setGlobalHostingFee(e.target.value)}
                    type="number"
                    size="small"
                    fullWidth
                />
            </Grid>

            <Grid item xs={12} sm={3}>
                <TextField
                    label="Global Power"
                    value={globalPower}
                    onChange={(e) => setGlobalPower(e.target.value)}
                    type="number"
                    size="small"
                    fullWidth
                />
            </Grid>

            <Grid item sm="auto">
                <Button variant="contained" onClick={applyGlobalChanges}>Apply</Button>
            </Grid>

            <Grid item sm="auto">
                <FormControl fullWidth>
                    <Select
                        value={downloadOption}
                        onChange={handleDownloadChange}
                        displayEmpty
                        variant="outlined"
                        renderValue={(value) =>
                            value ? (
                                <>
                                    <Download fontSize="small" sx={{ marginRight: 1 }} />
                                    {value === 'csv' ? 'Download as CSV' : 'Download by Grouped User'}
                                </>
                            ) : (
                                <Download fontSize="small" sx={{ marginRight: 1 }} />
                            )
                        }
                        sx={{
                            '.MuiSelect-select': {
                                display: 'flex',
                                alignItems: 'center',
                                padding: '8px 16px',
                                backgroundColor: '#1976d2',
                                color: 'white',
                                borderRadius: '4px',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                            },
                        }}
                    >
                        <MenuItem value="csv">
                            <Download fontSize="small" sx={{ marginRight: 1 }} /> Download as CSV
                        </MenuItem>
                        <MenuItem value="grouped">
                            <Download fontSize="small" sx={{ marginRight: 1 }} /> Download - Grouped By User
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>




    );
};

export default SearchAndFilters;
