import { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable,useMaterialReactTable,type MRT_ColumnDef} from 'material-react-table';
import { Box,SelectChangeEvent, Stack, TextField } from '@mui/material';
import axios from 'axios';
import { API_PATH } from '../../../App';
import { getAxiosConfig } from '../../../helpers/AuthHelper';
import { downloadGroupedCSV, WorkerData } from '../../utils/csvUtils';
import SearchAndFilters from './WorkerSearchAndFilter';
import TableLoader from '../GlobalComponents/TableLoader';

interface WorkerProps {
    fromDate: Date | null;
    setFromDate: (date: Date | null) => void;
    toDate: Date | null;
    setToDate: (date: Date | null) => void;
    searchTrigger: boolean;
}

const Worker: React.FC<WorkerProps> = ({ fromDate, setFromDate, toDate, setToDate, searchTrigger }) => {
    const [searchTerm, setSearchTerm] = useState<string[]>([]);
    const [rows, setRows] = useState<WorkerData[]>([]);
    const [searchOptions, setSearchOptions] = useState([]);
    const [selectedDays, setSelectedDays] = useState(1)
    const [globalHostingFee, setGlobalHostingFee] = useState<string>('');
    const [globalPower, setGlobalPower] = useState<string>('');
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 50,
    });
    const [downloadOption, setDownloadOption] = useState('');
    const [tableLoader, setTableLoader] = useState(false)


    useEffect(() => {
        fetchWorkerList();
    }, []);

    useEffect(() => {
        handleSearch()
    }, [searchTrigger]);
    
    useEffect(() => {
        if (fromDate && toDate) {
            const normalizedFromDate = new Date(fromDate);
            normalizedFromDate.setHours(0, 0, 0, 0);

            const normalizedToDate = new Date(toDate);
            normalizedToDate.setHours(0, 0, 0, 0);

            const differenceInMilliseconds = normalizedToDate.getTime() - normalizedFromDate.getTime();
            const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24) + 1;

            setSelectedDays(differenceInDays);
        }
    }, [fromDate, toDate]);

    const fetchWorkerList = () => {
        axios.get(API_PATH + '/finance/report/workerlist', getAxiosConfig())
            .then(response => {
                setSearchOptions(response.data);
            })
            .catch(error => {
                console.error("Error fetching worker list:", error);
            });
    };

    const fetchWorkerData = (fromTime: string, toTime: string, ids: string[]) => {
        setTableLoader(true)
        axios.post(API_PATH + '/finance/report/worker', {
            ids,
            fromTime,
            toTime,
        }, getAxiosConfig())
            .then(response => {
                const updatedData = response.data.map((item: any) => {
                    const user = item.workerId.split('.')[0];
                    return {
                        ...item,
                        user: user
                    };
                });

                setRows(updatedData);
                setTableLoader(false)
            })
            .catch(error => {
                console.error("Error fetching worker data:", error);
                setTableLoader(false)
            });
    };

    const handleSearch = () => {
        fetchWorkerData(formatDate(fromDate), formatDate(toDate), searchTerm);
    };

    const formatDate = (date: Date | null) => {
        if (!date) return '';
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };


    const totalExpenseBtc = useMemo(() => {
        return rows.reduce((acc, row) => acc + (row.totalSpend || 0), 0);
    }, [rows]);

    const totalExpenseDollar = useMemo(() => {
        return rows.reduce((acc, curr) => acc + (curr.expenseInDollar || 0), 0);
    }, [rows]);

    const columns = useMemo<MRT_ColumnDef<WorkerData>[]>(
        () => [

            {
                accessorKey: 'user',
                header: 'User',
                size: 150,

            },
            {
                accessorKey: 'workerId',
                header: 'Worker ID',
                size: 150,
            },
            {
                accessorKey: 'totalSpend',
                header: 'Expense (Btc)',
                aggregationFn: "sum",
                size: 150,
                AggregatedCell: ({ cell, table }) => (
                    <>
                        <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>

                            {cell.getValue<number>()?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 13,
                            })}
                        </Box>
                    </>
                ),
                Cell: ({ row }) => Number(row.original.totalSpend).toFixed(8),
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {totalExpenseBtc.toLocaleString('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 8,
                            })}
                        </Box>
                    </Stack>
                ),
            },
            {
                accessorKey: 'expenseInDollar',
                header: 'Expense ($)',
                size: 150,
                Cell: ({ row }) => `${Number(row.original.expenseInDollar).toFixed(8)}`,
                aggregationFn: "sum",
                AggregatedCell: ({ cell, table }) => (
                    <>
                        <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
                            {cell.getValue<number>()?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 13,
                            })}
                        </Box>
                    </>
                ),
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {totalExpenseDollar?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 10,
                            })}
                        </Box>
                    </Stack>
                )
            },
            {
                accessorKey: 'hostingFee',
                header: 'Hosting Fee',
                size: 150,
                Cell: ({ row }) => (
                    <TextField
                        value={row.original.hostingFee || 0.06}
                        type="number"
                        onChange={(e) => {
                            const newValue = e.target.value;
                            const updatedRow: WorkerData = {
                                ...row.original,
                                hostingFee: newValue,
                            };
                            setRows((prev) =>
                                prev.map((item, index) =>
                                    index === row.index ? updatedRow : item
                                )
                            );
                        }}
                        size="small"
                        sx={{ width: '100%' }}
                    />
                ),
                AggregatedCell: ({ row }) => {
                    const user = row.original.user;
                    const filteredRows = rows.filter(r => r.user === user);
                    const averageHostingFee = filteredRows.reduce((acc, r) => acc + parseFloat(r.hostingFee || '0.06'), 0) / filteredRows.length;

                    return (
                        <Stack>
                            <Box sx={{ color: "green", fontWeight: 'bold' }}>
                                {averageHostingFee.toFixed(4)}
                            </Box>
                        </Stack>
                    );
                },
                Footer: () => {
                    const averageHostingFee = rows.reduce((acc, row) => acc + parseFloat(row.hostingFee || '3.2'), 0) / rows.length;
                    return (
                        <Box color="warning.main">
                            {averageHostingFee.toFixed(4)}
                        </Box>
                    );
                },
            },
            {
                accessorKey: 'power',
                header: 'Power',
                size: 150,
                Cell: ({ row }) => (
                    <TextField
                        value={row.original.Power || 3.2}
                        type="number"
                        onChange={(e) => {
                            const newValue = e.target.value;
                            const updatedRow: WorkerData = {
                                ...row.original,
                                Power: newValue,
                            };
                            setRows((prev) =>
                                prev.map((item, index) =>
                                    index === row.index ? updatedRow : item
                                )
                            );
                        }}
                        size="small"
                        sx={{ width: '100%' }}
                    />
                ),
                AggregatedCell: ({ row }) => {
                    const user = row.original.user;
                    const filteredRows = rows.filter(r => r.user === user);
                    const averageHostingFee = filteredRows.reduce((acc, r) => acc + parseFloat(r.Power || '3.2'), 0) / filteredRows.length;

                    return (
                        <Stack>
                            <Box sx={{ color: "green", fontWeight: 'bold' }}>
                                {averageHostingFee.toFixed(4)}
                            </Box>
                        </Stack>
                    );
                },
                Footer: () => {
                    const averagePower = rows.reduce((acc, row) => acc + parseFloat(row.Power || '3.2'), 0) / rows.length;
                    return (
                        <Box color="warning.main">
                            {averagePower.toFixed(4)}
                        </Box>
                    );
                },
            },
            {
                accessorKey: 'income',
                header: 'Income',
                size: 150,
                Cell: ({ row }) => {
                    const hostingFee = parseFloat(row.original.hostingFee || '0.06');
                    const power = parseFloat(row.original.Power || '3.2');
                    const income = hostingFee * power * selectedDays * 24;
                    return income.toFixed(4);
                },
                AggregatedCell: ({ row }) => {
                    const user = row.original.user;
                    const totalIncome = rows
                        .filter(r => r.user === user)
                        .reduce((acc, r) => {
                            const hostingFee = parseFloat(r.hostingFee || '0.06');
                            const power = parseFloat(r.Power || '3.2');
                            const income = hostingFee * power * selectedDays * 24;
                            return acc + income;
                        }, 0);

                    return (
                        <Stack>
                            <Box sx={{ color: "green", fontWeight: 'bold' }}>
                                {totalIncome.toFixed(4)}
                            </Box>
                        </Stack>
                    );
                },
                Footer: () => {
                    const totalIncome = rows.reduce((acc, row) => {
                        const hostingFee = parseFloat(row.hostingFee || '0.06');
                        const power = parseFloat(row.Power || '3.2');
                        const income = hostingFee * power * selectedDays * 24;
                        return acc + income;
                    }, 0);

                    return (
                        <Box color="warning.main">
                            {totalIncome.toFixed(4)}
                        </Box>
                    );
                },
            },
            {
                accessorKey: 'total',
                header: 'Total',
                size: 150,
                Cell: ({ row }) => {
                    const expenseInDollar = Number(row.original.expenseInDollar);
                    const hostingFee = parseFloat(row.original.hostingFee || '0.06');
                    const power = parseFloat(row.original.Power || '3.2');
                    const total = (hostingFee * power * selectedDays * 24) - expenseInDollar;
                    return total.toFixed(4);
                },
                aggregationFn: "sum",
                AggregatedCell: ({ row }) => {
                    const user = row.original.user;
                    const totalSum = rows
                        .filter(row => row.user === user)
                        .reduce((acc, row) => {
                            const expenseInDollar = Number(row.expenseInDollar);
                            const hostingFee = parseFloat(row.hostingFee || '0.06');
                            const power = parseFloat(row.Power || '3.2');
                            const total = (hostingFee * power * selectedDays * 24) - expenseInDollar;
                            return acc + total;
                        }, 0);

                    return (
                        <Stack>
                            <Box sx={{ color: "#1976d2", fontWeight: 'bold' }}>
                                {totalSum.toFixed(4)}
                            </Box>
                        </Stack>
                    );
                },
                Footer: () => {
                    const totalSum = rows.reduce((acc, row) => {
                        const expenseInDollar = Number(row.expenseInDollar);
                        const hostingFee = parseFloat(row.hostingFee || '0.06');
                        const power = parseFloat(row.Power || '3.2');
                        const total = (hostingFee * power * selectedDays * 24) - expenseInDollar;
                        return acc + total;
                    }, 0);

                    return (
                        <Stack>
                            <Box color="warning.main">
                                {totalSum.toFixed(4)}
                            </Box>
                        </Stack>
                    );
                },
            },
        ],
        [rows, selectedDays]
    );

   


    const handleDownloadCSV = () => {
        const csvRows = [
            columns.map(col => col.header).join(','),
            ...rows.map(row => {
                return columns.map(col => {
                    const value = row[col.accessorKey as keyof WorkerData];

                    if (col.accessorKey === 'expense') {
                        return row.totalSpend;
                    }

                    if (col.accessorKey === 'expense_dollar') {
                        return row.expenseInDollar;
                    }

                    if (col.accessorKey === 'hostingFee') {
                        return parseFloat(row.hostingFee || '0.06');
                    }

                    if (col.accessorKey === 'power') {
                        return parseFloat(row.Power || '3.2');
                    }

                    if (col.accessorKey === 'income') {
                        const hostingFee = parseFloat(row.hostingFee || '0.06');
                        const power = parseFloat(row.Power || '3.2');
                        const income = hostingFee * power * selectedDays * 24;
                        return income.toFixed(4);
                    }

                    if (col.accessorKey === 'total') {
                        const expenseInDollar = Number(row.expenseInDollar);
                        const hostingFee = parseFloat(row.hostingFee || '0.06');
                        const power = parseFloat(row.Power || '3.2');
                        const total = (hostingFee * power * selectedDays * 24) - expenseInDollar;
                        return total.toFixed(4);
                    }

                    return typeof value === 'number' ? value.toFixed(2) : value ?? '';
                }).join(',');
            })
        ];


        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = `worker_data_${formatDate(new Date())}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        window.URL.revokeObjectURL(url);
    };

    const applyGlobalChanges = () => {
        setRows((prevRows) =>
            prevRows.map((row) => ({
                ...row,
                hostingFee: globalHostingFee,
                Power: globalPower,
            }))
        );
    };


    const table = useMaterialReactTable({
        columns,
        data: rows,
        state: {
            pagination,
        },
        onPaginationChange: setPagination,
        muiPaginationProps: {
            rowsPerPageOptions: [50, 100, 200, 500],
            showFirstButton: false,
            showLastButton: false,
        },
        enableRowSelection: false,
        enableGrouping: true,
    });

    const handleDownloadChange = (event: SelectChangeEvent<string>) => {
        const selectedOption = event.target.value;
        setDownloadOption(selectedOption);

        if (selectedOption === 'csv') {
            handleDownloadCSV();
        } else if (selectedOption === 'grouped') {
            downloadGroupedCSV(rows, selectedDays, formatDate);
        }
    };

    return (
        <>
            <SearchAndFilters
                searchOptions={searchOptions}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                fromDate={fromDate}
                setFromDate={setFromDate}
                toDate={toDate}
                setToDate={setToDate}
                handleSearch={handleSearch}
                downloadOption={downloadOption}
                handleDownloadChange={handleDownloadChange}
                globalHostingFee={globalHostingFee}
                setGlobalHostingFee={setGlobalHostingFee}
                globalPower={globalPower}
                setGlobalPower={setGlobalPower}
                applyGlobalChanges={applyGlobalChanges}
            />
            {tableLoader ?
                <TableLoader /> :
                <MaterialReactTable table={table} />
            }
            
        </>
    );
};

export default Worker;
